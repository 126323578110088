import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import image from 'img/blog/updates/custom_date.png';

const Content = () => {
  return (
    <div>
      <p>You can now specify your custom date range for your sessions search.</p>
      <p>
        {' '}
        We&apos;ve also added two additional date ranges, <strong>Past week</strong> and{' '}
        <strong>Past month</strong>. Every type of predefined date has information on it right with
        exact date that will be applied.
      </p>
      <ImageModal
        src={image}
        alt="LiveSession - specify date"
        title="You can specify custom fate range for your sessions search"
      />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'More date filtering options',
  url: '/blog/more-date-filtering-options',
  description: 'Use custom date range to search your sessions',
  author,
  img: image,
  date: '2019-02-07',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
